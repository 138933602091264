const SERVER = 'https://uchet.store';
// const SERVER = 'http://work.rr';

export default function (data = {}, url = "") {

    let circularln = document.getElementById('circularln');
    if (circularln) circularln.style.display = '';

    return fetch(SERVER + '/api' + url, {
        method: 'POST',
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
        .then(res => {
            if (circularln) circularln.style.display = 'none';
            return res.json()
        })
        .catch(e => {
            if (circularln) circularln.style.display = 'none';
            console.log(e)
        })

}
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function () {
    return <Grid item xs={11}>
        <Typography paragraph/>
        <Typography paragraph variant="caption">
            Для формирования заказа Вам потребуется ввести номер заказа и фамилию,
            в случае каких-либо затруднений, связывайтесь с нами по телефону, адресу эл. почты
            или приходите в наши Сервисные Центры.
        </Typography>
        <Typography paragraph variant="caption">
            Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены
            на платёжный шлюз ПАО СБЕРБАНК.
            Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме
            с использованием протокола шифрования SSL.
            В случае если Ваш банк поддерживает технологию безопасного проведения
            интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept,
            J-Secure для проведения платежа также может потребоваться ввод специального пароля.
            Настоящий сайт поддерживает 256-битное шифрование.
            Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК.
            Введённая информация не будет предоставлена третьим лицам за исключением случаев,
            предусмотренных законодательством РФ.
            Проведение платежей по банковским картам осуществляется в строгом соответствии
            с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.
        </Typography>
        <Typography component="div" className="logos"/>
        <Typography paragraph variant="caption">
            Наши реквизиты:
        </Typography>
        <Typography paragraph variant="caption">
            Индивидуальный предприниматель Самсонов Иван Николаевич, ИНН 253810717303, ОГРН 305253812300030,
            ОКВЭД 47.42, Юридический адрес: 105318 г. Москва Измайловское шоссе д. 29, кв. 136.
        </Typography>
    </Grid>

}
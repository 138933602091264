import React from 'react';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";

import Yandex from "./Yandex";
import Sberbank from "./Sberbank";

export default class extends React.Component {

    state = {
        status: 0,
        checked: true,
        month: 1,
        // paymentMethod: 'Sberbank',
        // paymentMethod: 'Yandex',
    }

    checkout() {

        let label = JSON.stringify({
            o: this.props.order.org_id,
            s: this.props.order.stock_id,
            r: this.props.order.rem_id,
            m: this.state.month,
            f: this.props.order.fio,
        })
        let description = "Продление гарантии для " + this.props.order.model + " " + this.renderLimitation(this.state.month);

        if (this.props.order.rem_id === 891) {
            Sberbank(this.renderAmount(), label, description);
        } else {
            Yandex(this.renderAmount(), label, description);
        }

        // if (this.state.paymentMethod === 'Sberbank') {
        //     Sberbank(this.renderAmount())
        // }

    }

    renderLimitationDate(unix) {

        let date = new Date(unix * 1000);
        return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear() + "г.";

    }

    renderLimitation(month) {
        return month < 13 ?
            "на " + month + " " + (month === 1 ? "месяц" : month < 5 ? "месяца" : "месяцев") :
            "пожизненно";
    }

    renderAmount() {
        return Math.round(this.state.month < 13 ?
            this.props.order.amount / 10 * this.state.month :
            this.props.order.amount * 1.5)
    }

    renderWarrantyText() {
        return <div>
            <Typography variant="h4">Договор публичной оферты</Typography>
            <Typography paragraph>
                Индивидуальный предприниматель Самсонов Иван Николаевич ИНН 253810717303, ОГРНИП 305253812300030,
                Юридический адрес: 105318, г. Москва, Измайловское шоссе, д. 29, кв. 136 (далее Сервисный Центр),
                публикует настоящий договор, являющийся публичным договором - офертой в адрес как физических,
                так и юридических лиц (далее Заказчик) о нижеследующем:
            </Typography>
            <Typography variant="h5"> Предмет договора - оферты.</Typography>
            <Typography paragraph>
                Сервисный Центр продлевает свои гарантийные обязательства перед Заказчиком на отремонтированное
                оборудование
                (далее Оборудование) по ранее заключенному договору на выбранный Заказчиком срок.
            </Typography>
            <Typography variant="h5">Срок действия договора - оферты.</Typography>
            <Typography paragraph>
                Текст данного Договора является публичной офертой.
                Факт оплаты, является безоговорочным принятием данного договора - оферты
                и Заказчик рассматривается как лицо, вступившее с Сервисным Центром в договорные отношения.
                Данный договор вступает в силу с момента оплаты при помощи данного интеренет ресурса.
                По письменному требованию Заказчика Сервисный Центр оформляет договор с подписями сторон.
            </Typography>
            <Typography variant="h5">Условия.</Typography>
            <Typography paragraph>
                Гарантийный срок исчисляется с момента передачи Оборудования Заказчику.
                Гарантия распространяется только на выполненные работы и замененные запчасти.
                Гарантия не распространяется на Оборудование, которое в течении гарантийного срока,
                подвергалось воздействию жидкости или механическому воздействию (удар, падение и тд),
                а также в случае любого разбора или нарушения целостности Оборудования (в том числе сторонними
                ремонтными организациями).
                Сервисный центр освобождается от гарантийных обязательств, в случае нарушением Заказчиком условий
                эксплуатации Оборудования, предусмотренных заводом-изготовителем.
                Заказчик имеет право воспользоваться расширенной гарантией не более 2 раз в течении срока ее действия.
                В случае проведения гарантийного ремонта,
                гарантия продлевается на количество дней нахождения Оборудования в гарантийном ремонте.
            </Typography>
        </div>
    }

    expandMore() {
        let month = this.state.month;
        if (month > 1) this.setState({month: --month})
    }

    expandLess() {
        let month = this.state.month;
        if (month < 13) this.setState({month: ++month})
    }

    handleCheck() {
        this.setState({checked: !this.state.checked})
    }

    render() {

        switch (this.state.status) {
            default:
                return <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={11}>
                        <Typography variant="h6" color="inherit">
                            Уважаемый(ая) {this.props.order.fio} !
                        </Typography>
                        <Typography paragraph/>
                        <Typography paragraph>
                            Благодарим за оказанное доверие и ремонт {this.props.order.model} в нашем Сервисном Центре!
                            Сообщаем Вам, что гарантийный срок 30 календарных дней, он указан
                            в гарантийном талоне, который Вам выдали при завершении заказа. В гарантийном
                            талоне обязательно указываются: дата , модель, серийный номер и выполненная работа.
                            Просьба проверять заполнение гарантийного талона и сохранять талон
                            в течение гарантийного срока.
                        </Typography>
                        <Typography paragraph>
                            Мы уверены в качестве оказываемых услуг и поэтому помимо стандартной,
                            предлагаем продлить гарантию на выполненную работу,
                            которая поможет избежать дополнительных расходов в будущем.
                            Продлить гарантию на ремонт {this.props.order.model} необходимо
                            до {this.renderLimitationDate(this.props.order.limitationTime)}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Button color="primary" variant="contained"
                                onClick={() => this.setState({status: 1})}
                        >
                            Продлить гарантию
                        </Button>
                    </Grid>
                </Grid>

            case 1:
                return <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                    spacing={5}
                >
                    <Grid item xs={11}>
                        {this.props.order.amount === 0 ? '' : "Всего за " + this.renderAmount() + " рублей"}
                    </Grid>
                    <Grid item xs={11}>
                        <ButtonGroup
                            size="large"
                            orientation="vertical"
                            variant="contained"
                        >
                            <Button variant="outlined" color="inherit" onClick={() => this.expandLess()}>
                                <ExpandLessIcon/>
                            </Button>
                            <Button color="secondary">
                                {this.renderLimitation(this.state.month)}
                            </Button>
                            <Button variant="outlined" color="inherit" onClick={() => this.expandMore()}>
                                <ExpandMoreIcon/>
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={8}>
                        <FormControlLabel
                            control={
                                <Checkbox color="primary" checked={this.state.checked}
                                          onChange={() => this.handleCheck()}/>
                            }
                            label="Принимаю публичную оферту"
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button color="primary" variant="contained"
                                        disabled={this.props.order.amount === 0 || !this.state.checked}
                                        onClick={() => this.checkout()}
                                >
                                    Продлить
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" color="secondary"
                                        onClick={() => this.setState({status: 0})}
                                >
                                    Отмена
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => this.setState({status: 2})}
                        >
                            Ознакомиться с условиями публичной оферты&nbsp;
                        </Link>
                    </Grid>
                </Grid>

            case 2:
                return <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                    spacing={5}
                >
                    <Grid item xs={11}>
                        {this.renderWarrantyText()}
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained"
                                onClick={() => this.setState({status: 1, checked: true})}
                        >
                            Принимаю публичную оферту
                        </Button>
                    </Grid>
                </Grid>

        }
    }

}

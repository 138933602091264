import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from "notistack";
import Circularln from './components/CircularIndeterminate';

ReactDOM.render(<SnackbarProvider preventDuplicate maxSnack={1}
                                  anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                  }}
                                  autoHideDuration={2000}
>
    <div id="circularln" style={{display: 'none'}}><Circularln/></div>
    <App />
</SnackbarProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();

import React from 'react';
import './App.css';
import Warranty from "./components/Warranty";
import Ready from "./components/Ready";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from '@material-ui/icons/Home';
import PhoneIcon from '@material-ui/icons/Phone';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import request from "./components/Request";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import {withSnackbar} from 'notistack';
import SberbankRequirements from "./components/SberbankRequirements";
import PolicyText from "./components/PolicyText";


const pages = [
    'Вход в личный кабинет...',
    'Мои заказы',
    'Проверка статуса заказа',
    'Ваш заказ выполнен!',
    'Продление гарантии',
    'Поздравляем, гарантия продлена!',
    'Информация о статусе заказа',
    'Важная информация',
]

// https://my.pr-servis.ru/1/1/891/Я

const getParams = window
    .location
    .pathname
    .split('/');

const order = {
    action: 'getOrder',
    org_id: +getParams[1],
    stock_id: +getParams[2],
    rem_id: +getParams[3],
    fio: getParams[4] !== undefined ? decodeURIComponent(getParams[4]) : ''
}

const isCallBack = getParams[1] === "callback";

export default withSnackbar(class extends React.Component {

    state = {
        status: isCallBack ? 5 : 2,
        prevStatus: isCallBack ? 5 : 2,
        checkOrderId: order.rem_id || '',
        checkSurename: order.fio === 'undefined' ? '' : order.fio || '',
        isRequestPerformed: false
    }

    orderInit(data) {

        order.org_id = data.org_id || 1;
        order.stock_id = order.stock_id || data.stock_id;
        order.rem_id = order.rem_id || +this.state.checkOrderId;
        order.amount = data.amount;
        order.checkoutTime = data.checkoutTime;
        order.limitationTime = data.limitationTime;
        order.fio = order.fio || data.customer || this.state.checkSurename;
        order.model = data.model;
        order.status = data.status;
        order.mustPay = data.mustPay;

        // console.log(order)

        let status = (order.status === 'выдали' && order.limitationTime > (Date.now() / 1000)) ?
            4 : // продлить гарантию
            order.status === 'готов' ?
                3 : // онлайн оплата за заказ
                6;
        this.setState({status});

    }

    componentDidMount() {

        if (!isCallBack && order.org_id > 0) {
            this.setState({isRequestPerformed: true})
            request(order)
                .then(data => {
                    this.setState({isRequestPerformed: false})
                    if (data === undefined) {
                        this.props.enqueueSnackbar('Нет соединения', {
                            variant: 'error',
                        });
                    } else if (data.result) {
                        this.orderInit(data);
                    } else {
                        this.setState({status: 2});
                    }
                })
        }

    }

    check() {

        let rem_id = this.state.checkOrderId;
        let surename = this.state.checkSurename;

        if (!rem_id || !surename) return false;

        let org_id = order.org_id || 1;

        this.setState({isRequestPerformed: true})
        request({
            action: 'customerAuth',
            org_id,
            rem_id,
            surename
        })
            .then(data => {
                this.setState({isRequestPerformed: false})
                if (data === undefined) {
                    this.props.enqueueSnackbar('Нет соединения', {
                        variant: 'error',
                    });
                } else if (data.result) {
                    this.orderInit(data);
                } else {
                    this.props.enqueueSnackbar('Неправильный номер или фамилия', {
                        variant: 'error',
                    });
                }
            })

    }

    checkSurenameHandler(value) {
        this.setState({checkSurename: value})
    }

    checkOrderIdHandler(value) {
        this.setState({checkOrderId: value})
    }

    renderContent(status) {
        switch (status) {
            case 8:
                return <Grid container>
                    <Grid item xs={11}>
                        {PolicyText()}
                    </Grid>
                </Grid>
            case 7:
                return <Grid container>
                    {SberbankRequirements()}
                </Grid>
            case 6:
                return <Grid item>
                    <Typography paragraph>
                        {
                            order.status === 'выдали' ?
                                'Заказ завершен' :
                                ['в процессе ремонта', 'ждем запчасти', 'готова диагностика', 'ждем ответа'].indexOf(order.status) > -1 ?
                                    'Статус заказа: ' + order.status :
                                    order.status === 'new' ?
                                        'Статус заказа: в процессе ремонта' :
                                        'Информация о заказе отсутствует'
                        }
                    </Typography>
                </Grid>
            case 5:
                return '';
            case 4:
                return <Warranty order={order}/>
            case 3:
                return (
                    <Grid container
                          direction="column"
                          justify="space-around"
                          alignItems="center"
                          spacing={4}
                    >
                        <Grid item xs={12}>
                            <Ready order={order}/>
                        </Grid>
                        {order.mustPay <= 0 ?
                            <Grid item xs={12}>
                                <Button color="primary" variant="contained"
                                        onClick={() => this.setState({status: 4})}
                                >
                                    Продлить гарантию
                                </Button>
                            </Grid> : ''}
                    </Grid>
                );
            default:
                return <div>
                    <Grid container
                          direction="column"
                          justify="space-around"
                          alignItems="center"
                          spacing={4}
                          style={
                              {background: "#ffffff", marginTop: "20px", marginBottom: "20px"}
                          }>
                        <Grid item xs={12}>
                            <TextField label="Номер заказа"
                                       value={this.state.checkOrderId}
                                       onChange={(e) => this.checkOrderIdHandler(e.target.value)}
                                       color="primary" autoFocus/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Фамилия"
                                       value={this.state.checkSurename}
                                       onChange={(e) => this.checkSurenameHandler(e.target.value)}
                                       color="primary"/>
                        </Grid>
                    </Grid>
                    <Button color="primary"
                            onClick={() => this.check()}
                            variant="contained"
                            disabled={!this.state.checkOrderId || !this.state.checkSurename || this.state.isRequestPerformed}
                    >
                        Проверить
                    </Button>
                </div>
        }
    }

    render() {
        return (
            <div className="App">
                <AppBar position="static" style={{backgroundColor: "#282c34"}}>
                    <Toolbar variant="dense">
                        <IconButton edge="start" color="inherit"
                                    onClick={() => {
                                        document.location.href = "https://pr-servis.ru";
                                    }}
                        >
                            <HomeIcon style={{marginRight: "10px"}}/>
                            <Typography variant="h6" color="inherit">
                                pr-servis.ru
                            </Typography>
                        </IconButton>
                        <IconButton href="tel:+74232056005" edge="start" color="inherit"
                                    style={{paddingLeft: "20%"}}
                        >
                            <PhoneIcon style={{marginRight: "10px"}}/>
                            <Typography variant="h6" color="inherit">
                                205-60-05
                            </Typography>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid container
                      justify="space-evenly"
                      alignItems="center"
                      className="App-content"
                >
                    <Grid item>
                        <Typography variant="h5" color="inherit" style={{margin: "10px"}}>
                            {pages[this.state.status]}
                        </Typography>
                    </Grid>
                    {this.renderContent(this.state.status)}

                    {(this.state.status === 7 || this.state.status === 8) ?
                        <Grid item>
                            <Button color="primary" variant="contained"
                                    onClick={() => this.setState({status: this.state.prevStatus})}
                            >
                                Ознакомлен
                            </Button>
                        </Grid> : ''}

                    <Grid container justify="space-evenly" style={{margin: "20px",}}>

                        {this.state.status !== 7 ?
                            <Grid item>
                                <Link component="button"
                                      variant="body2"
                                      onClick={() => {
                                          let prevStatus = (this.state.status === 8) ?
                                              this.state.prevStatus : this.state.status
                                          this.setState({
                                              status: 7,
                                              prevStatus,
                                          })
                                      }}
                                >
                                    Важная информация
                                </Link>
                            </Grid> : ''}

                        {this.state.status !== 8 ?
                            <Grid item>
                                <Link component="button"
                                      variant="body2"
                                      onClick={() => {
                                          let prevStatus = (this.state.status === 7) ?
                                              this.state.prevStatus : this.state.status
                                          this.setState({
                                              status: 8,
                                              prevStatus,
                                          })
                                      }}
                                >
                                    Политика конфиденциальности
                                </Link>
                            </Grid> : ''}

                        <Grid item>
                            <Link component="button"
                                  variant="body2"
                                  onClick={() => window.location.href = "https://appblog.ru"}
                            >
                                @appblog
                            </Link>
                        </Grid>

                    </Grid>
                </Grid>
            </div>
        )
    }

})
import request from "./Request";

export default function (ammount, label, description) {

    let amount = ammount * 100;
    let orderNumber = 'orderTime_' + Date.now();
    let returnUrl = 'my.pr-servis.ru/callback';
    let clientId = 0;
    let data = {
        amount,
        orderNumber,
        returnUrl,
        description,
        clientId,
        jsonParams: label
    }
    let queryString = '';
    for (let i in data) {
        queryString += i + '=' + encodeURIComponent(data[i]) + '&';
    }

    request({
        action: 'getSberbankPaymentLink',
        queryString
    })
        .then(data => {
            if (data.result) {
                window.location.href = data.formUrl;
            }
        })


}
import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Sberbank from "./Sberbank";
import Yandex from "./Yandex";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import PolicyText from "./PolicyText";

export default class extends React.Component {

    state = {
        policy: false
    }

    checkout() {

        let label = JSON.stringify({
            o: this.props.order.org_id,
            s: this.props.order.stock_id,
            r: this.props.order.rem_id,
            f: this.props.order.fio,
        });
        let description = "Оплата заказа №" + this.props.order.rem_id + " за ремонт " + this.props.order.model;

        if (this.props.order.rem_id === 891) {
            Sberbank(this.props.order.mustPay, label, description)
        } else {
            Yandex(this.props.order.mustPay, label, description)
        }

    }

    render() {

        return this.state.policy ?
            <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="center"
                spacing={5}
            >
                <Grid item xs={11}>
                    {PolicyText()}
                </Grid>
                <Grid item>
                    <Button color="primary" variant="contained"
                            onClick={() => this.setState({policy: false})}
                    >
                        Ознакомлен
                    </Button>
                </Grid>
            </Grid> :
            <div>
                <Typography paragraph>
                    {this.props.order.model} готов.
                </Typography>
                {this.props.order.mustPay > 0 ?
                    <Grid container direction="column" justify="space-around">
                        <Grid item>
                            <Typography paragraph>
                                Для получения необходимо оплатить {this.props.order.mustPay} руб.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => this.checkout()}>
                                Оплатить онлайн
                            </Button>
                        </Grid>
                    </Grid>
                    : ''}
            </div>

    }

}